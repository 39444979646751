// 自定义基础弹窗
.basic-modal {
  .ant-modal-content {
    padding: 0 !important;
    border-radius: 6px !important;
  }
  .ant-modal-header {
    height: 50px;
    padding: 13px 20px !important;
    border-radius: 6px 6px 0 0 !important;
    background-color: #fafafa !important;
    border-bottom: 1px solid #c0c4cc !important;
  }
  .ant-modal-close {
    width: 18px !important;
    height: 18px !important;
    top: 16px !important;
    inset-inline-end: 20px !important;
  }
  .ant-modal-body {
    overflow: hidden;
    padding: 0 20px !important;
  }
  .ant-modal-footer {
    padding: 0 20px 20px !important;
  }
}

// 表格滚动条
.ant-table {
  .ant-table-content,
  .ant-table-body {
    scrollbar-width: thin;
    scrollbar-color: #eaeaea transparent;
    scrollbar-gutter: stable;
  }
}
